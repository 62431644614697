<template>
  <v-dialog v-model="dialog" max-width="900px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        dark
        v-bind="attrs"
        v-on="on"
        class="rounded-xl"
        style="background: linear-gradient(268.1deg, #07204b, #1a347f)"
      >
        <span class="text-center mx-4 fn14-weight7">
          <a-icon type="plus" class="mr-2" />
          New E-Learning
        </span>
      </v-btn>
    </template>
    <v-form>
      <v-row class="pa-4" style="background: white">
        <v-col cols="6">
          <div class="fn20-weight7">New E-Learning</div>
        </v-col>

        <v-col cols="6" class="text-right" align="center" justify="end">
          <!-- <v-btn
            @click="submit()"
            outlined
            class="rounded-xl"
            style="color: #1a347f"
          >
            <span class="text-center fn14-weight7"> Save Draft </span>
          </v-btn> -->
        </v-col>

        <v-stepper v-model="start" vertical width="100%">
          <v-stepper-step :complete="start > 1" step="1" class="fn16">
            Name, Publish Date
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-row style="color: #1a347f" class="fn16">
              <v-col cols="6" class="pb-0"> Banner Name*: </v-col>
              <v-col cols="6" class="pb-0">Publish Date*:</v-col>
              <v-col cols="6" class="pb-0">
                <v-text-field
                  v-model="form.name"
                  hide-details
                  outlined
                  dense
                  placeholder="type your title here"
                />
              </v-col>
              <v-col cols="6">
                <v-menu
                  v-model="form.menufrom"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.date"
                      prepend-icon="mdi-calendar"
                      hide-details
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      solo
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.date"
                    range
                    @input="form.menufrom = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" class="pb-0">URL Link*:</v-col>
              <v-col cols="6" class="pb-0">
                <v-text-field
                  v-model="form.url"
                  outlined
                  dense
                  placeholder="type your URL here"
                />
              </v-col>
            </v-row>
            <v-btn color="primary" @click="start = 2"> Continue </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="start > 2" step="2">
            Add Images & Preview
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-col cols="12" class="pa-0">
              <v-row no-gutters align="center" justify="center">
                <v-col cols="12" class="mt-5 ma-10" id="step-3">
                  <v-img
                    v-model="form.picture_1"
                    @click="changePic1()"
                    src="@/assets/icons/upload.png"
                    class="mr-3 d-inline-block"
                    max-width="18"
                    max-height="22"
                  >
                  </v-img>
                  <span
                    style="color: #1a347f"
                    class="fn16-weight7"
                    @click="changePic1()"
                  >
                    Upload files
                  </span>
                  <br />
                  <span style="color: red" class="fn14"
                    >ใช้นามสกุล .png ขนาด 300*300
                  </span>
                  <v-col
                    cols="12"
                    md="3"
                    class="pl-5 pr-5 row fill-height align-center sortable-list"
                  >
                    <v-card dense light class="pa-1" v-if="showImage">
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-icon small light @click="RemoveImage"
                          >mdi-close</v-icon
                        >
                      </v-card-actions>

                      <v-img
                        :src="showImage"
                        width="200px"
                        aspect-ratio="1.8"
                        contain
                      ></v-img>
                    </v-card>
                  </v-col>
                  <input
                    type="file"
                    ref="image"
                    id="picTure1"
                    accept="image/png"
                    @change="showPicture1($event)"
                    style="display: none"
                    hidden
                /></v-col>
              </v-row>
            </v-col>
            <v-btn color="primary" class="mr-2" @click="start = 3">
              Continue
            </v-btn>
            <v-btn @click="start = 1"> Cancel </v-btn>
          </v-stepper-content>
        </v-stepper>
        <!-- <v-col cols="12">
          <v-checkbox
            v-model="form.publish"
            label="Publish this banner immediately"
            color="indigo"
            hide-details
            class="mx-8"
          />
        </v-col> -->
        <v-col cols="12" class="px-0 pb-0" align="center" justify="center">
          <v-btn
            :dark="start < 3 ? false : true"
            :disabled="start < 3"
            class="rounded-xl"
            :style="
              start < 3
                ? ''
                : 'background: linear-gradient(268.1deg, #07204b, #1a347f)'
            "
            width="250"
            @click="submit"
          >
            <span class="text-center fn14-weight7"> Upload </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-dialog>
</template>
<script>
import { Decode } from "@/services";
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    Datepicker,
  },
  props: {
    form: Object,
  },
  data() {
    return {
      dialog: false,
      start: 1,

      menufrom: false,
      product: "",
      items: [],
      title: "",
      categoryDescription: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
      content: "<br/><br/><br/><br/><br/>",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              // "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },
  created() {
    this.getAllProduct();
  },

  mounted() {
    console.log("this is current quill instance object", this.editor);
  },
  methods: {
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    async getAllProduct() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/products/
          `
      );
      console.log("getAllproducts", response.data);
      this.items = response.data.data;
    },
    // async getAllProduct() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/products/findAllAdmin?brandId=` +
    //       this.brand +
    //       "&typeId=" +
    //       this.type +
    //       "&categoryId=" +
    //       this.category
    //     // 'http://localhost:8081/types/'
    //   );
    //   console.log("getAllproducts", response.data);
    //   this.items = response.data.data;
    //   for (let i in this.items) {
    //     this.items[i].count = parseInt(i) + 1;
    //     //console.log(this.list);
    //   }
    // },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.pic1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    onPickFile2() {
      document.getElementById("file_input2").click();
    },
    RemoveImage() {
      // console.log(index, item);
      // if (item.id !== "-1") {
      //   this.Remove_img.push({
      //     id: item.id,
      //   });
      // }
      this.form.picture_1.splice(1, 1);
    },
    cancel() {
      // this.$router.push("Managebanners");
      this.start = 1;
      this.dialog = false;
    },
    async submit() {
      // if (this.$refs.createForm.validate(true)) {
      //   var user = JSON.parse(
      //     Decode.decode(localStorage.getItem("userBiAdmin"))
      //   );
      //   const auth = {
      //     headers: {
      //       Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
      //     },
      //   };
      //   const data = {
      //     // title: this.title,
      //     // description: this.brandDescription,
      //     // activeFlag: this.activeFlag,
      //     // description: this.content,
      //     imgUrl: this.pic1,
      //     productId: this.product,
      //   };
      //   if (this.activeFlag == true) {
      //     data.activeFlag = "1";
      //   } else if (this.activeFlag == false) {
      //     data.activeFlag = "0";
      //   }
      //   const response = await this.axios.post(
      //     `${process.env.VUE_APP_API}/banners`,
      //     data,
      //     auth
      //   );
      //   console.log("createCate", response);
      //   console.log(response.data.response_status);
      //   if (response.data.response_status == "SUCCESS") {
      //     this.$swal.fire({
      //       icon: "success",
      //       text: `สร้างแบนเนอร์สำเร็จ`,
      //       showConfirmButton: false,
      //       timer: 1500,
      //     });
      //     this.$router.push("Managebanners");
      //   } else {
      //     this.$swal.fire({
      //       icon: "error",
      //       text: `สร้างแบนเนอร์ไม่สำเร็จ`,
      //       showConfirmButton: false,
      //       timer: 1500,
      //     });
      //   }
      // }
      this.start = 1;
      this.dialog = false;
    },
  },
};
</script>
