<template>
  <div>
    <ManageELearning />
  </div>
</template>
<script>
import ManageELearning from "@/components/ELearning/ManageELearning";
export default {
  components: {
    ManageELearning,
  },
  created() {},
};
</script>
