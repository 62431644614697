<template>
  <v-form>
    <loading :active.sync="loading"></loading>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <div class="fn24-weight7">E-Learning</div>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <stepForm :dialog="dialog" :form.sync="form" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            v-model="selected"
            :headers="headers"
            :items="items"
            :search="search"
            item-key="name"
            show-select
            disable-pagination
            class="elevation-1"
          >
            <template v-slot:header.name="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.type="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.class="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.categories="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.points="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.views="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.action="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <v-row>
                <v-col cols="4" class="py-0">
                  <v-img :src="item.img" max-width="74" />
                </v-col>
                <v-col cols="8" class="py-0">
                  <div style="font-weight: 600">{{ item.name }}</div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.type`]="{ item }">
              <v-chip
                v-if="item.type == 'video'"
                outlined
                class="rounded-lg text-center"
                style="height: 21px"
                >Video</v-chip
              >
              <v-chip
                v-if="item.type == 'image'"
                outlined
                class="rounded-lg text-center"
                style="height: 21px"
                >Image</v-chip
              >
              <v-chip
                v-if="item.type == 'pdf'"
                outlined
                class="rounded-lg text-center"
                style="height: 21px"
                >PDF</v-chip
              >
            </template>
            <template v-slot:[`item.class`]="{ item }">
              <div style="font-weight: 600">{{ item.class }}</div>
            </template>
            <template v-slot:[`item.categories`]="{ item }">
              <div style="font-weight: 600">{{ item.categories }}</div>
            </template>
            <template v-slot:[`item.points`]="{ item }">
              <div style="font-weight: 600">{{ item.points }}</div>
            </template>
            <template v-slot:[`item.views`]="{ item }">
              <v-chip
                outlined
                class="rounded-lg text-center"
                style="height: 24px"
                >{{ item.views }}</v-chip
              >
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <v-btn
                  @click="UpdateELearning(item)"
                  dark
                  outlined
                  small
                  class="rounded-xl"
                  style="color: #4f4f4f"
                >
                  Edit
                </v-btn>
                <v-btn
                  @click="DeleteELearning(item)"
                  dark
                  outlined
                  small
                  class="rounded-xl ml-2"
                  style="color: #4f4f4f"
                >
                  Delete
                </v-btn>
              </v-row>
            </template>
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import stepForm from "@/components/Shared/stepFormELearning";
export default {
  components: {
    draggable,
    Loading,
    stepForm,
  },
  data() {
    return {
      selected: [],
      headers: [
        { text: "", value: "", align: "center" },
        { text: "Name", value: "name", width: "200" },
        { text: "Type", value: "type", align: "center" },
        { text: "Class", value: "class", align: "center" },
        { text: "Categories", value: "categories", align: "center" },
        { text: "Points", value: "points", align: "center" },
        { text: "Views", value: "views", align: "center" },
        { text: "Action", value: "action", align: "center" },
      ],
      items: [
        {
          name: "Farm dcl .mp4",
          img: require("@/assets/images/img-e-learning.png"),
          type: "video",
          class: "Silver",
          categories: "News",
          points: "2",
          views: "9177",
        },
        {
          name: "cdcmkcspccds dcsd.mp4",
          img: require("@/assets/images/img-e-learning1.png"),
          type: "video",
          class: "Silver",
          categories: "TalkaTECH",
          points: "3",
          views: "3064",
        },
        {
          name: "dcds,;dsc lcsd d.pdf",
          img: require("@/assets/images/img-e-learning2.png"),
          type: "pdf",
          class: "Silver",
          categories: "News",
          points: "2",
          views: "9195",
        },
        {
          name: "cdc  m dssldsc .mp4",
          img: require("@/assets/images/img-e-learning3.png"),
          type: "video",
          class: "Gold",
          categories: "News",
          points: "2",
          views: "3128",
        },
        {
          name: "dss  dckopko.mp4",
          img: require("@/assets/images/img-e-learning4.png"),
          type: "video",
          class: "Platinum",
          categories: "Meet the expert",
          points: "10",
          views: "9892",
        },
        {
          name: "dvkl g dcs.mp4",
          img: require("@/assets/images/img-e-learning5.png"),
          type: "video",
          class: "Platinum",
          categories: "Meet the expert",
          points: "2",
          views: "9011",
        },
      ],
      form: {
        name: "",
        date: new Date().toISOString().substr(0, 10),
        url: "",
        picture_1: "",
        menufrom: false,
        publish: false,
      },
      search: "",
      sortIndex: 0,
      user: "",
      loading: false,
    };
  },
  created() {
    // this.user = JSON.parse(
    //   Decode.decode(localStorage.getItem("userBiAdmin"))
    // );
    // this.getAllbrand();
  },
  methods: {
    async DragItems(val) {
      this.loading = true;
      console.log("this.itemsssssss", this.items);
      console.log("beforeAPI", val);
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("userBiAdmin"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
        },
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/brands/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      this.items = response.data.data;
      // for (let i in this.items) {
      //   this.items[i].count = parseInt(i) + 1;
      // }
      this.loading = false;
    },
    async getAllbrand() {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/categories`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAllbrandpe", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
      // console.log("itemBefore", this.items);
      // console.log('itemAfter',this.items);
    },
    UpdateELearning(val) {
      localStorage.setItem("CategoryData", Encode.encode(val));
      this.$router.push("EditELearning");
    },
    ViewELearning(val) {
      localStorage.setItem("CategoryData", Encode.encode(val));
      this.$router.push("ViewELearning");
    },
    async DeleteELearning(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/categoriesmxxx/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllbrand();
        }
      });
    },
    goToCreate() {
      this.$router.push("CreateCategory");
    },
  },
};
</script>
